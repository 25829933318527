import { post } from "./api";
export function loginAndStartJudge (params) {
    return post('/v1/jobfit/evaluation/start/wap', { request: params });
}
export function loginAndGetEvaluations (params) {
    return post('/v1/jobfit/evaluation/again/wap', { request: params });
}
export function submitEvaluation (params) {
    return post('/v1/jobfit/evaluation/submit/wap', { request: params });
}
export function saveEvaluation (params) {
    return post('/v1/jobfit/evaluation/save/wap', { request: params });
}
export function getJugeAgainProblems (params) {
    return post('/v1/jobfit/evaluation/detail/wap', { request: params });
}
export function batchJudge (params) {
    return post('/v1/jobfit/evaluation/batch/submit/wap', { request: params });
}